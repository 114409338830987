import React from "react";
import "./style.css";

function DashboardWeb() {
  return (
    <div className="main-right-section">
      <div className="dashboard-web-url-box" style={{ width: "81%" }}>
        <h4 className="main-right-header" style={{ paddingLeft: "0px" }}>
          URL
        </h4>
        <div className="dashboard-web-url-field">
          <span>https://mywebdomain.com</span>
          <button>
            <i
              className="ci-external_link"
              style={{ fontSize: "20px", color: "var(--primary-color)" }}
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardWeb;
