import { useState, useEffect, useContext } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import TouchContext from "@context/TouchContext";

const iconMore = [
  {
    text: "Document",
    url: "/",
    icon: "ci-info_square_outline",
  },
  {
    text: "Need support?",
    url: "/",
    icon: "ci-message",
  },
];

function SideBar() {
  const { touches, setTouches } = useContext(TouchContext);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [showSupport, setShowSupport] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSupport(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const changeTouch = (touchId) => {
    if (touchId === touches.filter((touch) => touch.isActive)[0]._id) {
      return;
    }
    const newTouches = [...touches];
    newTouches.forEach((touch) => {
      if (touch._id === touchId) {
        touch.isActive = true;
      } else {
        touch.isActive = false;
      }
    });
    localStorage.setItem("touch", JSON.stringify(newTouches));
    setTouches(newTouches);
    window.location.reload();
  };

  return (
    <ul
      className={`container-sidebar ${
        activeSidebar ? "container-sidebar--active" : null
      }`}
    >
      <li
        className={`hamburger-sidebar ${
          activeSidebar ? "hamburger-sidebar--active" : null
        }`}
      >
        <button
          onClick={() => setActiveSidebar(!activeSidebar)}
          style={{ padding: 0 }}
        >
          <i
            className="icon-hamburger-sidebar ci-hamburger"
            style={{ color: "#c4c4c4", fontSize: "24px" }}
          ></i>
        </button>
        {activeSidebar ? (
          <span className="sidebar-touch-text">Toggle panel</span>
        ) : null}
      </li>
      <li className="touch-border-bottom"></li>

      {touches.map((touch, ind) => {
        return (
          <li
            onClick={() => changeTouch(touch._id)}
            key={ind}
            className="touch-sidebar"
          >
            <img
              src={touch.image}
              alt={touch.name}
              className="icon-touch-sidebar"
            />
            {activeSidebar ? (
              <span className="sidebar-touch-text">{touch.name}</span>
            ) : null}
          </li>
        );
      })}
      <li className="touch-border-bottom"></li>

      <li className={`touch-sidebar`} style={{ cursor: "auto" }}>
        <button className="icon-more-sidebar icon-touch-sidebar">
          <i
            className="ci-plus"
            style={{ color: "#E3E3E3", fontSize: "24px" }}
          ></i>
        </button>
        {activeSidebar ? (
          <Link to="/" className="sidebar-touch-text link text-grey">
            newApp
          </Link>
        ) : null}
      </li>

      <ul className="icon-sidebar-wrap">
        {iconMore.map((list, ind) => {
          return (
            <li className="more-sidebar" key={ind}>
              <button className="icon-more-sidebar icon-touch-sidebar">
                <i
                  className={list.icon}
                  style={{
                    fontSize: "24px",
                    color:
                      showSupport && list.text === "Need support?"
                        ? "var(--primary-color)"
                        : "#000000",
                  }}
                ></i>
              </button>

              {showSupport && list.text === "Need support?" ? (
                <div className="alert-support-bubble"> Need support?</div>
              ) : null}

              {activeSidebar ? (
                <Link className="sidebar-touch-text link" to={list.url}>
                  {list.text}
                </Link>
              ) : null}
            </li>
          );
        })}
      </ul>
    </ul>
  );
}

export default SideBar;
