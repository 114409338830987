import { useRef, useContext } from "react";
import DatepickerContext from "@context/DatepickerContext";
import { useDay } from "@datepicker-react/hooks";
import getColor from "@utils/getColor";

function Day({ dayLabel, date }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate
  );

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      style={{
        padding: "8px",
        border: 0,
        color: getColorFn({
          selectedFirstOrLastColor: "#FFFFFF",
          normalColor: "#001217",
          selectedColor: "var(--primary-color)",
          rangeHoverColor: "#000000",
          disabledColor: "#808285",
        }),
        background: getColorFn({
          selectedFirstOrLastColor: "var(--primary-color)",
          normalColor: "#FFFFFF",
          selectedColor: "var(--primarylight-color)",
          rangeHoverColor: "var(--secondarylight-color)",
          disabledColor: "#FFFFFF",
        }),
      }}
    >
      {dayLabel}
    </button>
  );
}

export default Day;
