import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import TouchContext from "@context/TouchContext";
import UserContext from "@context/UserContext";
import {
  navBarPageList,
  navSetting,
  userDropdownList,
} from "@constants/pageList";
import "./style.css";

// const navSetting = [
//   {
//     onclick: () => {
//       alert("notifyHandler");
//     },
//     iconName: "ci-notification_outline_dot",
//   },
//   {
//     onclick: () => {
//       alert("settingHandler");
//     },
//     iconName: "ci-slider_01",
//     text: "Settings",
//   },
//   {
//     onclick: () => {
//       alert("reportHandler");
//     },
//     iconName: "ci-pie_chart_outline_25",
//     text: "Reports",
//   },
// ];

// const notifyHandler = () => {
//   alert("notifyHandler");
// };

function NavBar({ path }) {
  const { touches } = useContext(TouchContext);
  const { user } = useContext(UserContext);
  const [activeProfile, setActiveProfile] = useState(false);

  return (
    <div className="container-navbar">
      <div className="navbar-section">
        {touches.map((touch, ind) =>
          touch.isActive ? (
            <div className="touch-navbar" key={ind}>
              <img
                src={touch.image}
                alt={touch.name}
                className="icon-touch-navbar"
              />
              <span> {touch.name} </span>
            </div>
          ) : null
        )}
        <div className="nav-border-right"></div>

        <ul className="pages-navbar">
          {navBarPageList.map((page, ind) => {
            return (
              <li
                className={`page-navbar ${
                  page.url === path ? "page-navbar--active" : null
                }`}
                key={ind}
              >
                <Link to={page.url} className="link">
                  {page.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="nav-border-right"></div>
      </div>

      <div className="navbar-section">
        <ul className="settings-navbar">
          {navSetting.map((list, ind) => {
            return (
              <li key={ind} onClick={list.onclick}>
                <i
                  className={list.iconName}
                  style={{ color: "#2E3A59", fontSize: "18px" }}
                ></i>

                {list.text ? (
                  <span className="navbar-setting-text">{list.text}</span>
                ) : null}
              </li>
            );
          })}
        </ul>

        <div className="user-navbar">
          <span>{user.name}</span>
          <img src={user.image} alt="user" className="user-image" />
          <div className="user-profile">
            <button onClick={() => setActiveProfile(!activeProfile)}>
              <i
                className={activeProfile ? "ci-chevron_up" : "ci-chevron_down"}
                style={{ fontSize: "24px", color: "#2E3A59" }}
              ></i>
            </button>
            <div
              className={`user-profile-more ${
                activeProfile ? "user-profile-more--active" : null
              }`}
            >
              {userDropdownList.map((list, ind) => {
                return (
                  <Link
                    to={list.url}
                    className="user-profile-more-choice"
                    onClick={list.onclick ? list.onclick : null}
                    key={ind}
                  >
                    {list.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
