import React from "react";
import "./style.css";

function Dropdown({
  fieldName,
  dataObjectActivateState,
  setDataObjectActivateState,
  dataObjectState,
  setDataObjectState,
  listOfData,
  moreBoxClass,
  moreListClass,
}) {
  const isActive = fieldName
    ? dataObjectActivateState[fieldName]
    : dataObjectActivateState;

  const boxWrapClass = moreBoxClass
    ? "dropdown-box-wrap " + moreBoxClass
    : "dropdown-box-wrap";

  const dropdownListClass = moreListClass
    ? "dropdown-list-box " + moreListClass
    : "dropdown-list-box";

  const onClickDropdown = () => {
    if (fieldName) {
      setDataObjectActivateState({
        ...dataObjectActivateState,
        [fieldName]: !dataObjectActivateState[fieldName],
      });
    } else {
      setDataObjectActivateState(!dataObjectActivateState);
    }
  };

  const onClickItem = (item) => {
    if (fieldName) {
      setDataObjectState({ ...dataObjectState, [fieldName]: item });
      setDataObjectActivateState({
        ...dataObjectActivateState,
        [fieldName]: false,
      });
    } else {
      setDataObjectState(item);
      setDataObjectActivateState(false);
    }
  };
  return (
    <div className={boxWrapClass}>
      <div className="dropdown-box">
        <span>{fieldName ? dataObjectState[fieldName] : dataObjectState}</span>
        <button onClick={onClickDropdown} className="hidden-onprint">
          {dataObjectActivateState[fieldName] ||
          (dataObjectActivateState && !fieldName) ? (
            <i
              className="ci-chevron_up"
              style={{ fontSize: "10px", color: "#575757" }}
            ></i>
          ) : (
            <i
              className="ci-chevron_down"
              style={{ fontSize: "10px", color: "#575757" }}
            ></i>
          )}
        </button>
      </div>
      <ul className={`hidden ${isActive ? dropdownListClass : null}`}>
        {listOfData.map((item, ind) => {
          return (
            <li key={ind} onClick={() => onClickItem(item)}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Dropdown;
