import React from "react";

function BtnCue({
  fieldName,
  isSelectState,
  setIsSelectState,
  text,
  btnColor,
  iconName,
  size,
}) {
  return (
    <button
      onClick={() =>
        setIsSelectState({
          ...isSelectState,
          [fieldName]: !isSelectState[fieldName],
        })
      }
      className="hidden-onprint"
      style={{ position: "relative" }}
    >
      <i
        className={iconName || "ci-error_outline"}
        style={{ color: btnColor || "#575757", fontSize: size || "16px" }}
      ></i>
      <div className={!isSelectState[fieldName] ? "hidden" : "text-bubble"}>
        {text.map((row, ind) => {
          return <p key={ind}>{row}</p>;
        })}
      </div>
    </button>
  );
}

export default BtnCue;
