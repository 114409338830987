const initialGraph = {
  labels: [],
  datasets: [],
};

const initialDataGraph = {
  datasets: [
    {
      label: "#universal",
      backgroundColor: "rgba(170, 238, 203, 0.43)",
      borderColor: "#3d835f",
      borderWidth: 3,
      pointRadius: 1,
      pointHoverBackgroundColor: "#3d835f",
      pointHoverBorderColor: "#fff",
      pointHoverRadius: 8,
      pointHoverBorderWidth: 3,
      pointHitRadius: 10,
    },
    {
      label: "#cookie",
      backgroundColor: "rgba(255, 232, 18, 0.43)",
      borderColor: "#fcdc34",
      borderWidth: 3,
      pointRadius: 1,
      pointHoverBackgroundColor: "#fcdc34",
      pointHoverBorderColor: "#fff",
      pointHoverRadius: 8,
      pointHoverBorderWidth: 3,
      pointHitRadius: 10,
    },
  ],
};

const initialOptionGraph = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
    backgroundColor: "#fff",
    titleFontFamily: "Poppins",
    titleFontColor: "grey",
    titleFontSize: 10,
    titleMarginBottom: 8,
    titleFontStyle: "normal",
    bodyFontFamily: "Poppins",
    bodyFontColor: "#000",
    bodyFontSize: 20,
    bodyFontStyle: "bold",
    footerFontFamily: "Poppins",
    footerFontColor: "grey",
    footerFontSize: 8,
    borderColor: "#c0c0c0",
    borderWidth: 2,
    caretSize: 10,
    caretPadding: 10,
    yPadding: 10,
    xPadding: 10,
    cornerRadius: 10,
    displayColors: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
};

module.exports = {
  initialGraph,
  initialDataGraph,
  initialOptionGraph,
};
