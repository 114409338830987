const navBarPageList = [
  { name: "Dashboard", url: "/" },
  { name: "Consents", url: "/consent" },
  { name: "Request", url: "#/request" },
  { name: "Settings", url: "#/setting" },
  { name: "API", url: "#/api" },
];

const navSetting = [
  {
    onclick: () => {
      alert("notifyHandler");
    },
    iconName: "ci-notification_outline_dot",
  },
  {
    onclick: () => {
      alert("settingHandler");
    },
    iconName: "ci-slider_01",
    text: "Settings",
  },
  {
    onclick: () => {
      alert("reportHandler");
    },
    iconName: "ci-pie_chart_outline_25",
    text: "Reports",
  },
];

const userDropdownList = [
  { name: "Profile", url: "#/profile" },
  { name: "Upgrade account?", url: "#/upgrade" },
  { name: "Billing", url: "#/billing" },
  { name: "Account settings.", url: "#/accountSetting" },
  {
    name: "Logout",
    url: "/",
    onclick: () => {
      localStorage.clear();
      window.location.replace("/");
    },
  },
];

const loginHeadPageList = [
  { name: "Home", url: "/" },
  { name: "Help", url: "#/help" },
  { name: "Privacy", url: "#/privacy" },
  { name: "Terms", url: "#/terms" },
];

module.exports = {
  navBarPageList,
  navSetting,
  userDropdownList,
  loginHeadPageList,
};
