import { useState } from "react";
import "./style.css";
import BtnCue from "../../../components/BtnCue";

const statusShow = {
  Pending: "Pending for approve.",
  Approved: "Approved.",
  Rejected: "Rejected.",
};

// ตรง more เดี๋ยวรับค่ามาเป็น list ของบรรทัด ไม่ก็push เข้าไปเป็น list [41]
const consentDataList = [
  { key: "Transaction ID", type: "both" },
  { key: "Signature", type: "Universal" },
  { key: "Public Key", type: "Universal" },
  { key: "Timestamp", type: "both" },
  { key: "Expire Date", type: "both" },
  { key: "Data Subject", type: "Universal" },
  { key: "Data Subject ID", type: "both" },
  { key: "User Agent", type: "Cookie" },
  { key: "Touch Point", type: "both", more: [] },
  {
    key: "Data Protection Officer",
    type: "Universal",
    more: [],
  },
  { key: "Consent Type", type: "Universal" },
];

// รอ backend ของจริงมาค่อยใส่ prop getSelectConsent ใส่ arg เป็น id ของ transaction ไว้ get ข้อมูลใหม่หลัง approve / reject
// transaction id  ได้จาก selectConsent.consentData.transactionId
function TransactionMain({ currentTouch, selectConsent, setSelectConsent }) {
  const [selectHelp, setSelectHelp] = useState({
    dataProtectionOfficer: false,
    touchPoint: false,
  });
  const consentDataKeyList = Object.keys(selectConsent.consentData);
  const consentData = [];

  const downloadData =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(selectConsent));

  // ตรง more เดี๋ยวรับค่ามาเป็น list ของบรรทัด ไม่ก็push เข้าไปเป็น list [12]
  consentDataList.forEach((list) => {
    if (list.type === "both" || list.type === selectConsent.type) {
      const key = consentDataKeyList.filter(
        (key) => key.toLowerCase() === list.key.replace(/\s/g, "").toLowerCase()
      );
      consentData.push({
        name: key,
        key: list.key,
        value: selectConsent.consentData[key[0]] || "",
        more: list.more || null,
      });
    }
  });

  return (
    <div className="container-main-left">
      <p className="bookmark" style={{ width: "95%" }}>
        <span className="bookmark-parent-page">{currentTouch.name} &gt;</span>
        <span className="bookmark-parent-page"> Consents &gt; </span>
        <span className="bookmark-page">
          {selectConsent.consentData.transactionId}
        </span>
      </p>

      {/* action menu */}
      <div className="consentmain-menu hidden-onprint">
        <ul className="consentmain-menu-left">
          <button
            onClick={() => setSelectConsent(false)}
            className="consentmain-button"
          >
            &lt; Back
          </button>
          <a
            href={downloadData}
            download={selectConsent.consentData.transactionId + ".json"}
            className="consentmain-button"
          >
            Download Receipt
          </a>
          <button onClick={() => window.print()} className="consentmain-button">
            Print
          </button>
        </ul>
        {selectConsent.type === "Universal" ? (
          <ul className="consentmain-menu-right">
            <button className="consentmain-button">Reject</button>
            <button className="consentmain-button consentmain-button-green">
              Approve
            </button>
          </ul>
        ) : (
          <ul></ul>
        )}
      </div>

      {/* transaction body */}
      <div className="consentmain-body consent-transaction-body " width="100%">
        <p className="transaction-data-header">
          <span>
            <span className="transaction-type">
              {selectConsent.type} Consent
            </span>
            {selectConsent.type === "Universal" ? (
              <span className="transaction-property-value">
                {statusShow[selectConsent.status]}
              </span>
            ) : null}
          </span>
          {selectConsent.type === "Universal" ? (
            <span
              className="transaction-property-value"
              style={{ color: "#7d7d7d" }}
            >
              {selectConsent.consentData.consentVersion}
            </span>
          ) : null}
        </p>
        {/* transaction body - table section */}
        <table className="transaction-section-box trasnsaction-consentdata-table transaction-property-value">
          <tbody>
            {consentData.map((data, ind) => {
              return (
                <tr key={ind}>
                  <td className="trasnsaction-consentdata-key">{data.key}</td>
                  <td className="trasnsaction-consentdata-value">
                    <span style={{ marginRight: "5px" }}>{data.value}</span>
                    {data.more ? (
                      <BtnCue
                        fieldName={data.name}
                        isSelectState={selectHelp}
                        setIsSelectState={setSelectHelp}
                        text={["data.more[0]", "data.more[1]"]}
                        btnColor="#7d7d7d"
                        iconName="ci-help_circle_outline"
                        size="16px"
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* transaction body - consented section */}
        <div className=" transaction-section-box page-break-avoid">
          <h3 className="transaction-head-wrap">
            <i
              className="ci-circle_check"
              style={{ color: "#3D835F", fontSize: "20px" }}
            ></i>
            <span className="transaction-header">Consented Purpose</span>
          </h3>
          <ul className="transaction-list">
            {selectConsent.purpose.consented.map((purpose, ind) => {
              return (
                <li key={ind}>
                  <p>
                    <span className="transaction-header">
                      {purpose.purpose.shortCode}
                    </span>
                    <span className="transaction-header-description">
                      {purpose.purpose.description}
                    </span>
                  </p>
                  <ul className="transaction-data-list">
                    {purpose.data.map((data, index) => (
                      <li className="transaction-property-value" key={index}>
                        {data}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        {/* transaction body - table section */}
        <div className="transaction-section-box page-break-avoid">
          <p className="transaction-head-wrap">
            <i
              className="ci-off_close"
              style={{ color: "#FA4778", fontSize: "20px" }}
            ></i>
            <span className="transaction-header">Not Consent Purpose</span>
          </p>
          <ul className="transaction-list">
            {selectConsent.purpose.notConsented.map((purpose, ind) => {
              return (
                <li key={ind}>
                  <p>
                    <span className="transaction-header">
                      {purpose.purpose.shortCode}
                    </span>
                    <span className="transaction-header-description">
                      {purpose.purpose.description}
                    </span>
                  </p>
                  <ul className="transaction-data-list">
                    {purpose.data.map((data, index) => (
                      <li className="transaction-property-value" key={index}>
                        {data}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="transaction-section-box page-break-avoid">
          <p className="transaction-head-wrap">
            <i
              className="ci-layers"
              style={{ color: "#2E3A59", fontSize: "20px" }}
            ></i>
            <span className="transaction-header">
              Data Processor or where data storing
            </span>
          </p>
          <ul className="transaction-list">
            {selectConsent.dataProcessor.map((list, ind) => {
              return (
                <li
                  key={ind}
                  className="transaction-property-value"
                  style={{ paddingLeft: "10px" }}
                >
                  {list}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TransactionMain;
