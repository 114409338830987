import { useHistory } from "react-router-dom";
import Logo from "@assets/image/data-guardian-consent-logo.png";

const link = `<link rel="stylesheet" href="https://tipaya-poc.dataguardian.co/assets/cookie-cmp.min.css">
<script src="https://tipaya-poc.dataguardian.co/assets/cookie-cmp.js"></script>`;
function CookieConsent() {
  const history = useHistory();

  return (
    <div className="container-createconsent">
      <header className="header-createconsent">
        <div className="createconsent-logo-wrap">
          <img className="createconsent-logo" src={Logo} alt="logo" />
          <div className="createconsent-header-wrap">
            <p className="createconsent-header-topic">Cookie Consent</p>
            <p className="createconsent-header-subtopic">
              A tool for consent any personal data.
            </p>
          </div>
        </div>
        <div className="createconsent-text">
          To create consent for touch point Foursquare please fill in below.
        </div>
        <div
          className="createconsent-bar"
          style={{
            backgroundColor: "var(--primarylight-color)",
            width: "100%",
          }}
        ></div>
      </header>

      <div className="body-consent-script">
        <div className="consent-link-box">{link}</div>
        <button
          onClick={() => navigator.clipboard.writeText(link)}
          className="copy-btn"
        >
          Copy!
        </button>
      </div>

      <footer className="footer-createconsent">
        <button
          className="button-green createconsent-button"
          onClick={() => history.push("/")}
        >
          Back
        </button>
      </footer>
    </div>
  );
}

export default CookieConsent;
