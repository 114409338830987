import "./style.css";
import { purposeList } from "@constants/consentPurposeList.js";
import Dropdown from "@components/DropdownList/purpose.js";
import { purposeData } from "@constants/dataFilterList.js";
import { useState } from "react";

function CreateConsentData({
  newConsent,
  setNewConsent,
  selectPurpose,
  setSelectPurpose,
}) {
  const [suggestData, setSuggestData] = useState({ index: 0, list: [] });

  const addNewPurpose = () => {
    const data = [...newConsent.consentData];
    const boolean = [...selectPurpose];
    data.push({ purpose: { shortCode: "", description: "" }, data: [""] });
    boolean.push({ purpose: false, data: [false] });
    setNewConsent({ ...newConsent, consentData: data });
    setSelectPurpose(boolean);
  };

  const getPurpose = (value, pInd, typing) => {
    const data = [...newConsent.consentData];
    const boolean = [...selectPurpose];
    data[pInd].purpose = value;
    boolean[pInd].purpose = !typing ? !selectPurpose[pInd].purpose : false;
    setNewConsent({ ...newConsent, consentData: data });
    setSelectPurpose(boolean);
  };

  const deletePurpose = (pInd) => {
    const newConsentGenerate = { ...newConsent };
    newConsentGenerate.consentData.splice(pInd, 1);
    setNewConsent(newConsentGenerate);
  };

  const addNewData = (ind) => {
    const newConsentGenerate = { ...newConsent };
    newConsentGenerate.consentData[ind].data.push("");
    setNewConsent(newConsentGenerate);
  };

  const getData = (e, pInd, cInd) => {
    const newConsentGenerate = { ...newConsent };
    newConsentGenerate.consentData[pInd].data[cInd] = e.target.value;
    const dataFilter = purposeData.filter(
      (list) =>
        e.target.value.length !== 0 &&
        list.length !== e.target.value.length &&
        list.substr(0, e.target.value.length).toLowerCase() ===
          e.target.value.toLowerCase()
    );
    setSuggestData({ index: pInd, list: dataFilter });
    setNewConsent(newConsentGenerate);
  };
  const selectData = (list, pInd, cInd) => {
    const newConsentGenerate = { ...newConsent };
    newConsentGenerate.consentData[pInd].data[cInd] = list;
    setSuggestData({ index: -1, list: [] });
    setNewConsent(newConsentGenerate);
  };

  const deleteData = (pInd, cInd) => {
    const newConsentGenerate = { ...newConsent };
    newConsentGenerate.consentData[pInd].data.splice(cInd, 1);
    setNewConsent(newConsentGenerate);
  };

  return (
    <div className="container-createconsent-child" style={{ width: "100%" }}>
      <h3 className="createconsent-topic">Consent data</h3>
      <div className="container-createconsentdata">
        {newConsent.consentData.map((purpose, ind) => {
          return (
            <div
              key={ind}
              className="createconsentdata-purpose-box"
              id={"header" + ind}
            >
              <div style={{ width: "48%" }}>
                <div className="purpose-header">
                  <h4>Purpose</h4>
                  <button
                    onClick={() => deletePurpose(ind)}
                    className="clear-purpose-btn"
                    style={{
                      display:
                        newConsent.consentData.length === 1 ? "none" : null,
                    }}
                  >
                    <i
                      className="ci-off_close"
                      style={{ fontSize: "24px", color: "#2E3A59" }}
                    ></i>
                  </button>
                </div>
                <div>
                  <Dropdown
                    value={purpose.purpose.shortCode}
                    dataObject={purpose.purpose}
                    dataObjectActivateState={selectPurpose}
                    setDataObjectActivateState={setSelectPurpose}
                    pInd={ind}
                    getValue={getPurpose}
                    listOfData={purposeList}
                  />
                </div>
              </div>
              {/* data list of each purpose */}
              <div
                onMouseLeave={() => setSuggestData({ index: -1, list: [] })}
                style={{ width: "48%" }}
              >
                <div className="purpose-header">
                  <h4>Data</h4>
                </div>
                {purpose.data.slice(0, -1).map((data, dInd) => {
                  return (
                    <div
                      className="input-field"
                      key={dInd}
                      style={{ margin: "10px 0" }}
                    >
                      {data}

                      <button onClick={() => deleteData(ind, dInd)}>
                        <i
                          className="ci-close_small"
                          style={{ fontSize: "15px", color: "#2E3A59" }}
                        ></i>
                      </button>
                    </div>
                  );
                })}
                <div className="input-field-wrap" style={{ margin: "5px 0" }}>
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Data to collect for this purpose."
                    id={`purposeData${ind}`}
                    onChange={(e) => getData(e, ind, purpose.data.length - 1)}
                    value={purpose.data[purpose.data.length - 1]}
                    autoComplete="off"
                  />
                  <ul
                    className="dropdown-list-box"
                    style={{
                      display:
                        suggestData.index !== ind ||
                        suggestData.list.length === 0
                          ? "none"
                          : null,
                      width: "100%",
                    }}
                  >
                    {suggestData.list.map((list, key) => {
                      return suggestData.index === ind ? (
                        <li
                          key={key}
                          onClick={() =>
                            selectData(list, ind, purpose.data.length - 1)
                          }
                        >
                          {list}
                        </li>
                      ) : null;
                    })}
                  </ul>
                </div>
                <button
                  onClick={() => addNewData(ind)}
                  disabled={purpose.data[purpose.data.length - 1] === ""}
                  className="createconsentdata-btn-text"
                >
                  Add new data mapping.
                </button>
              </div>
            </div>
          );
        })}
        <button
          onClick={addNewPurpose}
          disabled={
            !newConsent.consentData[newConsent.consentData.length - 1].purpose
              .shortCode
          }
          className="createconsentdata-btn-text"
        >
          <a href={"#header" + (newConsent.consentData.length - 1)}>
            {" "}
            Add new purpose.
          </a>
        </button>
      </div>
    </div>
  );
}

export default CreateConsentData;
