import { useContext } from "react";
import "./style.css";
import TouchContext from "@context/TouchContext";

function Transaction({ height, width, transactionStyle, bgColor, btnColor }) {
  const { touches } = useContext(TouchContext);
  const purpose = [];
  const statement = [];

  for (let i = 1; i <= 6; i++) {
    statement.push(
      <div
        key={i}
        className="transaction-mock-bar"
        style={{
          height: 0.02 * height + "px",
          margin: 0.004 * height + "px 0",
        }}
      ></div>
    );
  }

  for (let i = 1; i <= 3; i++) {
    if (transactionStyle === "C") {
      purpose.push(
        <div
          key={i}
          className="transaction-mock-bar transaction-mock-bar-head flex-column-justify"
          style={{
            height: 0.02 * height + "px",
            margin: 0.01 * height + "px 0",
          }}
        ></div>
      );
    } else {
      purpose.push(
        <div className="flex-column-justify" key={i}>
          <div
            className="transaction-mock-bar transaction-mock-bar-head"
            style={{
              width: "50%",
              height: 0.02 * height + "px",
              margin: 0.005 * height + "px 0",
            }}
          ></div>
          <div
            className="transaction-mock-bar"
            style={{
              height: 0.015 * height + "px",
              margin: 0.003 * height + "px 0",
            }}
          ></div>
          <div
            className="transaction-mock-bar"
            style={{
              height: 0.015 * height + "px",
              margin: 0.003 * height + "px 0",
            }}
          ></div>
        </div>
      );
    }
  }

  return (
    <div
      className="transaction-content"
      style={{
        height: height + "px",
        width: width + "px",
        backgroundColor: `${bgColor ? bgColor : "#F2F2F2"}`,
      }}
    >
      <div
        className={`transaction-about ${
          transactionStyle === "A" ? null : "transaction-about-column"
        }`}
      >
        <img
          src={touches.filter((touch) => touch.isActive === true)[0].image}
          alt="touch"
          className="transaction-logo"
          style={{
            height: 0.1 * height + "px",
            width: 0.1 * height + "px",
          }}
        />
        <div className="transaction-about-topic">
          <div
            className="transaction-mock-bar"
            style={{
              height: 0.03 * height + "px",
              margin: 0.01 * height + "px 0",
            }}
          ></div>
          <div
            className={`transaction-mock-bar ${
              transactionStyle === "C" ? "transaction-about-absolute" : null
            }`}
            style={{
              width: "50%",
              height: 0.02 * height + "px",
              margin: 0.015 * height + "px 5%",
            }}
          ></div>
        </div>
      </div>
      <div className="transaction-statement flex-column-justify">
        {statement}
      </div>
      <div
        className="transaction-purposes"
        style={{
          margin: 0.02 * height + "px 0",
        }}
      >
        <div className="transaction-purpose-box">
          <i
            className="ci-circle_check_outline"
            style={{
              color: btnColor ? btnColor : "#c4c4c4",
              fontSize: 0.04 * height + "px ",
            }}
          ></i>
          <div className="transaction-purpose">
            <p
              className="transaction-topic"
              style={{
                fontSize: 0.03 * height + "px ",
              }}
            >
              Purpose
            </p>
            {purpose}
          </div>
        </div>
        <div className="transaction-purpose-box">
          <i
            className="ci-circle_check_outline"
            style={{
              color: "#c4c4c4",
              fontSize: 0.04 * height + "px ",
            }}
          ></i>
          <div className="transaction-purpose">
            <p
              className="transaction-topic"
              style={{
                fontSize: 0.03 * height + "px ",
              }}
            >
              Purpose
            </p>
            {purpose}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
