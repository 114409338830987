import "./style.css";
import Transaction from "@components/Transaction";

function CreateConsentAbout({ newConsent, setNewConsent }) {
  const getValue = (e, field) => {
    const data = { ...newConsent, [field]: e.target.value };
    setNewConsent(data);
  };

  return (
    <div className="container-createconsent-child">
      <h3 className="createconsent-topic">About consent.</h3>
      <div className="container-createconsentabout">
        <Transaction height={450} width={300} transactionStyle="A" />
        <div className="content-createconsentabout" style={{ width: "35%" }}>
          <div className="input-field-wrap">
            <label htmlFor="name">Consent name</label>
            <input
              className="input-field"
              type="text"
              placeholder="Company registration"
              id="name"
              onChange={(e) => getValue(e, "name")}
              value={newConsent.name}
            />
          </div>
          <div>
            <input
              id="forWeb"
              type="checkbox"
              onChange={(e) =>
                setNewConsent({ ...newConsent, forWeb: e.target.checked })
              }
            />
            <label htmlFor="forWeb" style={{ paddingLeft: "10px" }}>
              This is website
            </label>
          </div>
          <div className="input-field-wrap">
            <label htmlFor="domain">
              For website require domain name/sub domain.
            </label>
            <input
              className="input-field"
              type="text"
              placeholder="https://..."
              id="domain"
              onChange={(e) => getValue(e, "domain")}
              value={newConsent.domain}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateConsentAbout;
