import Login from "@pages/Login";
import Dashboard from "@pages/Dashboard";
import Consent from "@pages/Consent";
import CreateConsent from "@pages/CreateUniversalConsent";
import CookieConsent from "@pages/CookieConsent";
import Register from "@pages/Register";

const allPages = {
  // path ของ user
  dashboard: {
    url: "/",
    page: Dashboard,
    layout: true,
  },
  consent: {
    url: "/consent",
    page: Consent,
    layout: true,
  },
  createConsent: {
    url: "/createConsent",
    page: CreateConsent,
  },
  cookieConsent: {
    url: "/cookieConsent",
    page: CookieConsent,
  },
  // path ของ guest
  login: {
    url: "/",
    page: Login,
  },
  register: {
    url: "/register",
    page: Register,
  },
};

const permissionList = {
  guest: [allPages.login, allPages.register],
  user: [
    allPages.dashboard,
    allPages.consent,
    allPages.createConsent,
    allPages.cookieConsent,
  ],
};

export default permissionList;
