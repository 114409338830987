import "./App.css";
import "@assets/WEBFONT/coolicons.css";
import { useState } from "react";
import UserContext from "@context/UserContext";
import PageContext from "@context/PageContext";
import TouchContext from "@context/TouchContext";
import PrivateRoutes from "./containers/PrivateRoute";

const noTouch = [
  {
    id: "noTouchYet",
    name: "noTouchYet",
    image:
      "https://res.cloudinary.com/dmtmyh1hg/image/upload/v1607618399/marvel/1296885_4_mrwzfb.jpg",
    isActive: true,
  },
];

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || { role: "guest" }
  );
  const [touches, setTouches] = useState(
    JSON.parse(localStorage.getItem("touch")) || noTouch
  );
  const [page, setPage] = useState("/");

  return (
    <div className="App">
      <PageContext.Provider value={{ page, setPage }}>
        <UserContext.Provider value={{ user, setUser }}>
          <TouchContext.Provider value={{ touches, setTouches }}>
            <PrivateRoutes />
          </TouchContext.Provider>
        </UserContext.Provider>
      </PageContext.Provider>
    </div>
  );
}

export default App;
