import React from "react";
import { useState, useEffect, useContext } from "react";
import "./style.css";
import axios from "../../config/axios";
import { Link, useHistory } from "react-router-dom";
import logo from "@assets/image/dg-notext-white-transparent.png";
import achive from "@assets/image/achive.png";
import UserContext from "@context/UserContext";
import TouchContext from "@context/TouchContext";
import { loginHeadPageList } from "@constants/pageList";

function Login() {
  const history = useHistory();
  const { setUser } = useContext(UserContext);
  const { setTouches } = useContext(TouchContext);
  const [value, setValue] = useState({ email: "", password: "" });
  const [validate, setValidate] = useState({ password: false, email: false });

  const disableBtn = () => {
    for (let key in validate) {
      if (!validate[key]) return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+\.[a-zA-Z]*$/.test(
        value.email
      )
    ) {
      setValidate({ ...validate, email: true });
    } else {
      setValidate({ ...validate, email: false });
    }
    // eslint-disable-next-line
  }, [value.email]);

  useEffect(() => {
    if (value.password.length >= 8) {
      setValidate({ ...validate, password: true });
    } else {
      setValidate({ ...validate, password: false });
    }
    // eslint-disable-next-line
  }, [value.password]);

  const getValue = (e, field) => {
    const data = { ...value, [field]: e.target.value };
    setValue(data);
  };
  const loginHandler = (e) => {
    e.preventDefault();
    axios
      .post("/api/auth/signin", {
        email: value.email,
        password: value.password,
      })
      .then((res) => {
        localStorage.clear();
        localStorage.setItem("ACCESS_TOKEN", res.data.accessToken);
        const userData = {
          role: "user",
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          image: res.data.image,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        setUser(userData);
        const touchesList = res.data.touchId;
        touchesList.forEach((touch, ind) => {
          if (ind === 0) {
            touch.isActive = true;
          } else {
            touch.isActive = false;
          }
          // บรรทัดด้านล่างเพิ่มขึ้นมาเฉพาะกิจ ต้องลบทิ้งหากมี property totalPage จาก backend แล้ว
          // บอกจำนวนpage ของtransactionทั้งหมดของtouchIdนั้นๆ
          touch.totalPage = 5;
          // บอกวันที่สร้างtouch yyyy-mm-dd
          touch.createDate = "2020-01-01";
        });
        localStorage.setItem("touch", JSON.stringify(touchesList));
        setTouches(touchesList);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="login-container">
      <div className="bg-login bg-login-under"></div>
      <div className="bg-login bg-login-top">
        <div className="logo-login-wrap">
          <img src={logo} alt="logo" className="logo-login" />
        </div>
        <img src={achive} alt="decorate key" className="key-picture-login" />
        <div className="login-text-wrap">
          <p>Welcome to Data Guardian Platform.</p>
          <p>Login on the right side.</p>
          <button
            onClick={() => {
              history.push("/register");
            }}
            className="login-to-register"
          >
            Don’t have an acount?
          </button>
        </div>
      </div>
      <div className="login-content">
        <ul className="login-link-box">
          {loginHeadPageList.map((page, ind) => {
            return (
              <li key={ind}>
                <Link to={page.url} className="link">
                  {page.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="login-text-wrap">
          <p>Welcome to Data Guardian Platform.</p>
        </div>
        <div className="login-text-wrap">
          <p>Login</p>
          <p className="login-subtopic">To manage all about privacy.</p>
        </div>
        <div className="login-text-wrap" style={{ width: "80%" }}>
          <div className="input-field-wrap" style={{ width: "100%" }}>
            <div className="input-field-login">
              <div className="input-field-login-svg">
                <i
                  className="ci-mail_open"
                  style={{ fontSize: "18px", color: "#000" }}
                ></i>
              </div>
              <input
                type="text"
                onChange={(e) => getValue(e, "email")}
                placeholder="Email Address"
                value={value.email}
              />
            </div>
          </div>
          <div className="input-field-wrap" style={{ width: "100%" }}>
            <div className="input-field-login">
              <div className="input-field-login-svg">
                <i
                  className="ci-user_check"
                  style={{ fontSize: "18px", color: "#000" }}
                ></i>
              </div>
              <input
                type="password"
                onChange={(e) => getValue(e, "password")}
                placeholder="Password"
                value={value.password}
              />
            </div>
          </div>
          <button
            className="login-input-action-btn"
            onClick={loginHandler}
            disabled={disableBtn()}
          >
            Login
          </button>
        </div>
        <div className="login-btn-wrap">
          <p>Other login methods.</p>
          <div className="login-btn-row-wrap">
            <button className="login-action-btn" disabled={true}>
              <i
                className="ci-qr_code"
                style={{ fontSize: "20px", color: "#000" }}
              ></i>

              <span>QR Code</span>
            </button>
            <button className="login-action-btn" disabled={true}>
              <i
                className="ci-qr_code"
                style={{ fontSize: "20px", color: "#000" }}
              ></i>

              <span>Biometrics</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
