import { useState, useContext, useEffect } from "react";
import TouchContext from "@context/TouchContext";
import { START_DATE } from "@datepicker-react/hooks";
import Main from "@pages/Consent/ConsentMain";
import Management from "@pages/Consent/ConsentManagement";
import TransactionMain from "@pages/Consent/TransactionMain";
import TransactionManagement from "@pages/Consent/TransactionManagement";
import axios from "axios";
import "./style.css";

// ตั้งให้ default date filter ย้อนหลัง 1 ปี
const date = new Date();
const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
const firstDay = new Date(
  date.getFullYear() - 1,
  date.getMonth(),
  date.getDate()
);

const defaultFilter = {
  action: "Approve",
  status: "All status",
  type: "All types",
  date: {
    startDate: firstDay,
    endDate: today,
    focusedInput: START_DATE,
  },
  page: { current: 1, total: 1 },
};

function Consent() {
  const { touches } = useContext(TouchContext);
  const [filter, setFilter] = useState(defaultFilter);
  const [reportList, setReportList] = useState([]);
  const [selectConsent, setSelectConsent] = useState(false);
  let currentTouch = touches.filter((touch) => touch.isActive)[0];

  const fetchReportList = async () => {
    const { _id, totalPage } = touches.filter(
      (touch) => touch.isActive === true
    )[0];
    try {
      // ใช้ touchId fetch
      // อยากเปลี่ยน path เป็น GET /consents/${_id}?page=${filter.page.current}
      const res = await axios.get(`api/consent/${_id}`);
      const consentList = res.data.consentList;
      consentList.forEach((list) => (list.isSelect = false));
      setReportList(consentList);
      const newFilter = { ...defaultFilter };
      newFilter.page = { current: 1, total: totalPage };
      setFilter(newFilter);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReportList();
    // eslint-disable-next-line
    currentTouch = touches.filter((touch) => touch.isActive)[0];
    // eslint-disable-next-line
  }, [touches]);

  const getSelectConsent = async (id) => {
    try {
      // ใช้ transactionId ในการ fetch
      // อยากเปลี่ยน path เป็น GET /consents/transactions/${id}
      const res = await axios.get(`api/consent/detail/${id}`);
      const consent = res.data;
      setSelectConsent(consent);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {selectConsent ? (
        <div className="container-main">
          <TransactionMain
            currentTouch={currentTouch}
            selectConsent={selectConsent}
            setSelectConsent={setSelectConsent}
            getSelectConsent={getSelectConsent}
          />
          <TransactionManagement
            currentTouch={currentTouch}
            selectConsent={selectConsent}
          />
        </div>
      ) : (
        <div className="container-main">
          <Main
            currentTouch={currentTouch}
            reportList={reportList}
            filter={filter}
            setFilter={setFilter}
            setReportList={setReportList}
            getSelectConsent={getSelectConsent}
          />
          <Management reportList={reportList} filter={filter} />
        </div>
      )}
    </>
  );
}

export default Consent;
