import "./style.css";

function Pagination({ state, setState, pagePropName, maxPagi }) {
  const pageAfter = Math.floor(maxPagi / 2);
  const pageBefore = maxPagi - pageAfter - 1;

  const numList = [];
  if (state[pagePropName].total <= maxPagi) {
    for (let i = 1; i <= state[pagePropName].total; i++) numList.push(i);
  } else if (state[pagePropName].current - pageBefore <= 1) {
    for (let i = 1; i <= maxPagi; i++) numList.push(i);
  } else if (
    state[pagePropName].current + pageAfter >
    state[pagePropName].total
  ) {
    for (
      let i = state[pagePropName].total - maxPagi + 1;
      i <= state[pagePropName].total;
      i++
    )
      numList.push(i);
  } else {
    for (
      let i = state[pagePropName].current - pageBefore;
      i <= state[pagePropName].current + pageAfter;
      i++
    )
      numList.push(i);
  }

  const selectPage = (num) => {
    const newState = { ...state };
    newState[pagePropName].current = num;
    setState(newState);
  };
  const previousPage = () => {
    const newState = { ...state };
    newState[pagePropName].current -= 1;
    setState(newState);
  };
  const nextPage = () => {
    const newState = { ...state };
    newState[pagePropName].current += 1;
    setState(newState);
  };

  return (
    <ul className="pagination">
      {state[pagePropName].current !== 1 ? (
        <li>
          <button onClick={previousPage} className="hidden-onprint">
            <i
              className="ci-chevron_left"
              style={{ fontSize: "20px", color: "#BDBDBD" }}
            ></i>
          </button>
        </li>
      ) : null}
      {numList[0] !== 1 ? <li>...</li> : null}

      {numList.map((num) => {
        return (
          <li key={num}>
            <button
              onClick={() => selectPage(num)}
              className={`pagination-number ${
                state[pagePropName].current === num
                  ? "pagination-number--select"
                  : "hidden-onprint"
              }`}
            >
              {num}
            </button>
          </li>
        );
      })}

      {numList[numList.length - 1] < state[pagePropName].total ? (
        <li>...</li>
      ) : null}
      {state[pagePropName].current !== state[pagePropName].total ? (
        <li>
          <button onClick={nextPage} className="hidden-onprint">
            <i
              className="ci-chevron_right"
              style={{ fontSize: "20px", color: "#BDBDBD" }}
            ></i>
          </button>
        </li>
      ) : null}
    </ul>
  );
}

export default Pagination;
