import { useContext, useState, useEffect } from "react";
import TouchContext from "@context/TouchContext";
import Dropdown from "@components/DropdownList";

const filterList = ["CSV", "JSON"];

function ConsentManagement({ reportList, filter }) {
  const { touches } = useContext(TouchContext);
  const [selectFilter, setSelectFilter] = useState(false);
  const [fileType, setFileType] = useState("File type");
  const [urlApi, setUrlApi] = useState(false);
  const [selectReportList, setSelectReportList] = useState([]);

  useEffect(() => {
    setUrlApi(false);
  }, [filter]);

  useEffect(() => {
    const selectReport = reportList.filter((record) => record.isSelect);
    const selectReportIdList = selectReport.map((report) => report._id);
    setSelectReportList(selectReportIdList);
    setFileType("File type");
  }, [reportList]);

  const onDownload = () => {
    const downloadData = { filetype: fileType, idList: selectReportList };
    alert("Send " + JSON.stringify(downloadData) + " to get file.");
  };

  const generateAPI = () => {
    const touchId = touches.filter((touch) => touch.isActive)[0]._id;
    const filterStatus = filter.status === "All status" ? "All" : filter.status;
    const filterType = filter.type === "All types" ? "All" : filter.type;
    const dateStart = filter.date.startDate.toJSON().slice(0, 10);
    const dateStop = filter.date.endDate.toJSON().slice(0, 10);
    const urlPath = `http://157.230.255.22:3000/api/consent/filter/${touchId}/${filterType}/${filterStatus}/${dateStart}/${dateStop}/`;
    setUrlApi(urlPath);
  };

  return (
    <div className="container-main-right">
      <h4
        className="main-right-header hidden-onprint"
        style={{ marginTop: "15px" }}
      >
        Export current consent report.
      </h4>
      <div className="management-box hidden-onprint" style={{ marginTop: "0" }}>
        <Dropdown
          dataObjectActivateState={selectFilter}
          setDataObjectActivateState={setSelectFilter}
          dataObjectState={fileType}
          setDataObjectState={setFileType}
          listOfData={filterList}
          moreBoxClass="consent-management-dropdown-box"
          moreListClass="consent-management-dropdown-list-box"
        />
      </div>
      <button
        className="button-green management-box hidden-onprint"
        onClick={onDownload}
        disabled={
          selectReportList.length === 0 ||
          filterList.findIndex((list) => list === fileType) === -1
        }
        style={{ width: "81%", marginBottom: "0" }}
      >
        Download file
      </button>
      {!urlApi ? (
        <div
          className="management-box hidden-onprint"
          style={{ textAlign: "left" }}
        >
          <p>Do you want to export as a API from current filtered scope?</p>
          <p>
            <button
              className="link"
              onClick={generateAPI}
              style={{ color: "#337EB5", paddingLeft: "0" }}
            >
              Click here
            </button>
            to auto generate API endpoint.
          </p>
        </div>
      ) : (
        <div
          className="management-box"
          style={{ textAlign: "left", wordBreak: "break-all" }}
        >
          <p>API URL</p>
          <a
            href={urlApi}
            target="_blank"
            rel="noreferrer"
            className="api-link"
          >
            {urlApi}
          </a>
          <button
            onClick={() => navigator.clipboard.writeText(urlApi)}
            className="copy-btn hidden-onprint"
          >
            Copy!
          </button>
        </div>
      )}
    </div>
  );
}

export default ConsentManagement;
