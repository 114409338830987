import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

function DashboardSetup() {
  const history = useHistory();
  return (
    <>
      <div className="main-right-section">
        <h4 className="main-right-header" style={{ marginBottom: "0" }}>
          Create new consent.
        </h4>
        <div
          className="container-consent-management"
          style={{ borderWidth: "0", marginTop: "0" }}
        >
          <button
            onClick={() => history.push("/createConsent")}
            className="button-grey management-box"
          >
            Create Universal Consent
          </button>
          <button
            onClick={() => history.push("/cookieConsent")}
            className="button-grey management-box"
          >
            Create Cookie Consent
          </button>
        </div>
      </div>
      <div className="container-dashboard-setup">
        <h4 className="main-right-header">Setup Instruction</h4>
        <p>Document for developer who need to integrate with website. </p>
        <div className="dashboard-setup-consent-wrap">
          <p>Universal consent integration.</p>
          <p className="dashboard-setup-consent-link">
            - Website integration guidline.
          </p>
          <p className="dashboard-setup-consent-link">- CSS styling guidline</p>
        </div>
        <div className="dashboard-setup-consent-wrap">
          <p>Cookie consent integration.</p>
          <p className="dashboard-setup-consent-link">
            - Website integration guidline.
          </p>
          <p className="dashboard-setup-consent-link">- CSS styling guidline</p>
        </div>
      </div>
    </>
  );
}

export default DashboardSetup;
