import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import permissionList from "../config/roles";
import Layout from "@containers/Layout";
import NotFound from "@pages/Notfound";
import UserContext from "@context/UserContext";

function PrivateRoutes() {
  const userContext = useContext(UserContext);
  const pageList = permissionList[userContext.user.role];

  return (
    <Switch>
      {pageList.map((page, ind) => (
        <Route exact path={page.url} key={ind}>
          {page.layout ? (
            <Layout path={page.url}>
              <page.page />
            </Layout>
          ) : (
            <page.page />
          )}
        </Route>
      ))}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default PrivateRoutes;
