import "./style.css";

function Dropdown({
  value,
  dataObject,
  dataObjectActivateState,
  setDataObjectActivateState,
  pInd,
  getValue,
  listOfData,
}) {
  const isActive = dataObjectActivateState[pInd].purpose;
  const onClickDropdown = () => {
    const newDataObjActivate = [...dataObjectActivateState];
    newDataObjActivate[pInd].purpose = !dataObjectActivateState[pInd].purpose;
    setDataObjectActivateState(newDataObjActivate);
  };

  const changeValue = (e, field) => {
    const item = { ...dataObject, [field]: e.target.value };
    getValue(item, pInd, true);
  };
  return (
    <div className="purpose-dropdown-container">
      <div className="dropdown-box-wrap" style={{ width: "100%" }}>
        <div className="dropdown-box">
          {dataObject.action ? (
            <input
              type="text"
              onChange={(e) => changeValue(e, "shortCode")}
              placeholder="Type purpose here."
              className="purpose-input"
              value={dataObject.shortCode}
            />
          ) : (
            <span style={{ color: value ? "black" : "grey", fontSize: "14px" }}>
              {value || "purpose"}
            </span>
          )}
          <button onClick={onClickDropdown}>
            {dataObjectActivateState[pInd].purpose ? (
              <i
                className="ci-chevron_up"
                style={{ fontSize: "10px", color: "#575757" }}
              ></i>
            ) : (
              <i
                className="ci-chevron_down"
                style={{ fontSize: "10px", color: "#575757" }}
              ></i>
            )}
          </button>
        </div>
        <ul
          className={`hidden ${
            isActive ? "dropdown-list-box purpose-dropdown-list-box" : null
          }`}
        >
          {listOfData.map((item, ind) => {
            return (
              <li key={ind} onClick={() => getValue(item, pInd)}>
                {item.shortCode || item.action}
              </li>
            );
          })}
        </ul>
      </div>
      {dataObject.action ? (
        <input
          type="text"
          onChange={(e) => changeValue(e, "description")}
          placeholder="Type purpose description here."
          className="description-input"
          value={dataObject.description}
        />
      ) : (
        <p style={{ width: "100%" }}> {dataObject.description}</p>
      )}
    </div>
  );
}

export default Dropdown;
