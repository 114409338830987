import { useState } from "react";
import BtnCue from "@components/BtnCue";

function GraphBar({
  consent,
  consentType,
  colorPrimary,
  colorSecondary,
  textList,
}) {
  const [isSelect, setIsSelect] = useState({ cue: false });
  return (
    <div className="dashboardmain-graph-bar-box">
      <div className="dashboardmain-graph-bar-topic">
        <span>
          {consentType.slice(0, 1).toUpperCase() + consentType.slice(1)} consent
        </span>
        <BtnCue
          fieldName="cue"
          isSelectState={isSelect}
          setIsSelectState={setIsSelect}
          text={textList}
          btnColor="#D0D1D2"
        />
      </div>
      <div className="dashboardmain-graph-bar-wrap">
        <p>
          <span className="dashboardmain-consent-count">
            {consent[consentType].total + " "}
          </span>
          Total
        </p>
        <div
          className="bar"
          style={{
            width: "100%",
            backgroundColor: colorSecondary,
          }}
        >
          <div
            className="bar"
            style={{
              width: consent[consentType].percent,
              backgroundColor: colorPrimary,
            }}
          ></div>
        </div>

        <div className="dashboardmain-data-wrap">
          <div className="dashboardmain-data-status-wrap">
            <div className="dashboardmain-line-wrap">
              <div
                className="dashboardmain-line"
                style={{
                  backgroundColor: colorPrimary,
                  width: "30px",
                }}
              ></div>
              <div className="null-box"></div>
            </div>
            <div className="dashboardmain-data-detail">
              <p className="dashboardmain-data-status-type"> Active</p>
              <p className="dashboardmain-data-status-count">
                {consent[consentType].trackable + " users"}
              </p>
            </div>
          </div>
          <div className="dashboardmain-data-status-wrap">
            <div className="dashboardmain-line-wrap">
              <div
                className="dashboardmain-line"
                style={{
                  backgroundColor: colorSecondary,
                  width: "30px",
                }}
              ></div>
              <div className="null-box"></div>
            </div>
            <div className="dashboardmain-data-detail">
              <p className="dashboardmain-data-status-type"> Untrackable</p>
              <p className="dashboardmain-data-status-count">
                {consent[consentType].untrackable} users
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphBar;
