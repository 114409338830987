import { useState, useContext, useEffect } from "react";
import TouchContext from "@context/TouchContext";
import DatepickerContext from "@context/DatepickerContext";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import Month from "./Month";
import NavButton from "./NavButton";
import Dropdown from "@components/DropdownList";
import { getYearList } from "@utils/date";
import "./style.css";

function Datepicker({ dateFrom, setDate, dateProp, datepickerActivateStatus }) {
  const { touches } = useContext(TouchContext);
  const [focusMonth, setFocusMonth] = useState({ year: 2021, month: 5 });
  const [selectFocusMonth, setSelectFocusMonth] = useState({
    year: false,
    month: false,
  });

  const createTouchYear = +touches
    .filter((touch) => touch.isActive === true)[0]
    .createDate.slice(0, 4);

  const yearList = getYearList(createTouchYear);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
  } = useDatepicker({
    startDate: dateFrom[dateProp].startDate,
    endDate: dateFrom[dateProp].endDate,
    focusedInput: dateFrom[dateProp].focusedInput,
    onDatesChange: handleDateChange,
    changeActiveMonthOnSelect: false,
    firstDayOfWeek: 0,
    numberOfMonths: 2,
  });

  // while open date picker: focus on 1 month before end date
  // by default: end date set to today
  useEffect(() => {
    const lastDate = dateFrom[dateProp].endDate;
    const previousMonthFirstDate = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth() - 1,
      1
    );
    goToDate(previousMonthFirstDate);
    // eslint-disable-next-line
  }, [datepickerActivateStatus]);

  useEffect(() => {
    goToDate(new Date(focusMonth.year, focusMonth.month - 1, 1));
  }, [focusMonth, goToDate]);

  function handleDateChange(data) {
    const newDateFrom = { ...dateFrom };
    if (!data.focusedInput) {
      newDateFrom[dateProp] = { ...data, focusedInput: START_DATE };
      setDate(newDateFrom);
    } else {
      newDateFrom[dateProp] = data;
      setDate(newDateFrom);
    }
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      {/* month selection */}
      <ul className="date-picker-month-select">
        <li>
          <NavButton onClick={goToPreviousMonths} iconName="ci-chevron_left" />
        </li>
        <li className="date-picker-header-cue">month:</li>
        <li>
          <Dropdown
            fieldName="month"
            dataObjectActivateState={selectFocusMonth}
            setDataObjectActivateState={setSelectFocusMonth}
            dataObjectState={focusMonth}
            setDataObjectState={setFocusMonth}
            listOfData={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            moreBoxClass="datePicker-dropdown-box"
            moreListClass="datePicker-dropdown-list-box"
          />
        </li>
        <li className="date-picker-header-cue">year:</li>
        <li>
          <Dropdown
            fieldName="year"
            dataObjectActivateState={selectFocusMonth}
            setDataObjectActivateState={setSelectFocusMonth}
            dataObjectState={focusMonth}
            setDataObjectState={setFocusMonth}
            listOfData={yearList}
            moreBoxClass="datePicker-dropdown-box"
            moreListClass="datePicker-dropdown-list-box"
          />
        </li>

        <li>
          <NavButton onClick={goToNextMonths} iconName="ci-chevron_right" />
        </li>
      </ul>
      <div
        style={{
          display: "grid",
          margin: "32px 0 0",
          gridTemplateColumns: `repeat(${activeMonths.length}, 200px)`,
          gridGap: "0 64px",
        }}
      >
        {activeMonths.map((month) => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
