import React from "react";

function TransactionManagement({ selectConsent }) {
  const urlApi = `https://api.dataguardian.co/apis/transaction/${selectConsent.consentData.transactionId}`;
  return (
    <div className="container-main-right">
      <div
        className="management-box"
        style={{ textAlign: "left", wordBreak: "break-all" }}
      >
        <h4
          className="main-right-header hidden-onprint"
          style={{ padding: "5px 0" }}
        >
          API URL
          <button
            onClick={() => navigator.clipboard.writeText(urlApi)}
            className="copy-btn hidden-onprint"
          >
            Copy!
          </button>
        </h4>
        <a href={urlApi} target="_blank" rel="noreferrer" className="api-link">
          {urlApi}
        </a>
      </div>
    </div>
  );
}

export default TransactionManagement;
