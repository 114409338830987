import { useContext, useEffect } from "react";
import "./style.css";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import Main from "./Main";
import PageContext from "@context/PageContext";

function Layout({ children, path }) {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage(path);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-layout">
      <SideBar />
      <NavBar path={path} />
      <Main page={children} />
    </div>
  );
}

export default Layout;
