import React from "react";
import WebSelect from "./DashboardWeb";
import Setup from "./DashboardSetup";

function DashboardManagement() {
  return (
    <div className="container-main-right">
      <WebSelect />
      <Setup />
    </div>
  );
}

export default DashboardManagement;
