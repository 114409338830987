import { useState, useEffect, useContext, useReducer } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import TouchContext from "@context/TouchContext";
import About from "./CreateUniversalConsentAbout";
import Data from "./CreateUniversalConsentData";
import Setting from "./CreateUniversalConsentSetting";
import Script from "./CreateUniversalConsentScript";
import Logo from "@assets/image/data-guardian-consent-logo.png";

const aboutStep = {
  max: 4,
};
const initConsent = {
  name: "",
  domain: "",
  statement: "",
  font: "Arial",
  layout: "A",
  bgColor: "#F2F2F2",
  btnColor: "#3D835F",
};

function stepReducer(state, action) {
  switch (action.type) {
    case "previous":
      return { step: state.step - 1 };
    case "next":
      return { step: state.step + 1 };
    case "cancel":
      return { step: 0 };
    default:
      throw new Error();
  }
}

function CreateUniversalConsent() {
  const { touches } = useContext(TouchContext);
  const history = useHistory();
  const [newConsent, setNewConsent] = useState(initConsent);
  const touchId = touches.filter((touch) => touch.isActive)[0]._id;
  const [selectPurpose, setSelectPurpose] = useState([
    { purpose: false, data: [false] },
  ]);

  const [state, dispatch] = useReducer(stepReducer, { step: 1 });

  useEffect(() => {
    if (state.step === 0) history.push("/");
  }, [state.step, history]);

  useEffect(() => {
    setNewConsent({
      ...initConsent,
      consentData: [
        { purpose: { shortCode: "", description: "" }, data: [""] },
      ],
      touchId,
    });
    // eslint-disable-next-line
  }, []);

  const disablebtn =
    (state.step === 1 &&
      (!newConsent.name || (newConsent.forWeb && !newConsent.domain))) ||
    (state.step === 2 &&
      !!newConsent.consentData.filter(
        (data) =>
          !data.purpose.shortCode ||
          !data.purpose.description ||
          data.data.filter((list) => !list).length
      ).length) ||
    (state.step === 3 &&
      (!newConsent.statement ||
        !newConsent.font ||
        !newConsent.bgColor ||
        !newConsent.btnColor));

  return (
    <div className="container-createconsent">
      {/* header */}
      <div className="header-createconsent" id="header">
        <div className="createconsent-logo-wrap">
          <img
            className="createconsent-logo"
            src={Logo}
            alt="logo"
            height="50%"
          />
          <div className="createconsent-header-wrap">
            <p className="createconsent-header-topic">Universal Consent</p>
            <p className="createconsent-header-subtopic">
              A tool for consent any personal data.
            </p>
          </div>
        </div>
        <div className="createconsent-text">
          To create consent for touch point Foursquare please fill in below.
        </div>
        <div
          className="createconsent-bar"
          style={{
            backgroundColor: "var(--primarylight-color)",
            width: "100%",
          }}
        >
          <div
            className="createconsent-bar"
            style={{
              backgroundColor: "var(--primary-color)",
              width: `${(state.step / aboutStep.max) * 100}%`,
            }}
          ></div>
        </div>
      </div>

      {/* non header */}
      <div className="nonheader-createconsent">
        {/* body */}
        <div className="body-createconsent">
          {state.step === 1 ? (
            <About newConsent={newConsent} setNewConsent={setNewConsent} />
          ) : state.step === 2 ? (
            <Data
              newConsent={newConsent}
              setNewConsent={setNewConsent}
              selectPurpose={selectPurpose}
              setSelectPurpose={setSelectPurpose}
            />
          ) : state.step === 3 ? (
            <Setting newConsent={newConsent} setNewConsent={setNewConsent} />
          ) : (
            <Script newConsent={newConsent} setNewConsent={setNewConsent} />
          )}
        </div>
        {/* footer */}
        <div className="footer-createconsent">
          <a href="#header">
            <button
              className="button-grey-step createconsent-button"
              onClick={() =>
                dispatch({
                  type: state.step !== 1 ? "previous" : "cancel",
                })
              }
            >
              {state.step !== 1 ? "Previous" : "Cancel"}
            </button>
          </a>
          <a href="#header">
            <button
              className="button-green createconsent-button"
              onClick={() =>
                dispatch({
                  type: state.step < aboutStep.max ? "next" : "cancel",
                })
              }
              disabled={disablebtn}
            >
              {state.step < aboutStep.max ? "Next" : "Exit"}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CreateUniversalConsent;
