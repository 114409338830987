import { useState } from "react";
import Dropdown from "@components/DropdownList";
import Datepicker from "@components/DatePicker";
import Pagination from "@components/Pagination";
import { getShortDate } from "@utils/date";
import "./style.css";

const table = [
  { header: "STATUS", name: ["status"] },
  { header: "TYPE", name: ["type"] },
  { header: "Date/Time", name: ["date", "time"] },
  { header: "Transaction ID", name: ["transactionId"] },
  { header: "User ID", name: ["clientId"] },
  { header: "Expire date", name: ["expireDate"] },
];

const filterList = {
  action: ["Approve"],
  status: ["All status", "Approved", "Pending", "Rejected"],
  type: ["All types", "Universal", "Cookie"],
};

const defaultSelectFilter = {
  action: false,
  status: false,
  type: false,
  date: false,
};

function ConsentMain({
  currentTouch,
  reportList,
  setReportList,
  filter,
  setFilter,
  getSelectConsent,
}) {
  const [selectFilter, setSelectFilter] = useState(defaultSelectFilter);
  const [selectAllReport, setSelectAllReport] = useState(false);

  const selectOne = (index) => {
    const newReportList = [...reportList];
    newReportList[index].isSelect = !newReportList[index].isSelect;
    setReportList(newReportList);
  };
  const selectAll = () => {
    const newReportList = [...reportList];
    newReportList.forEach((report) => (report.isSelect = !selectAllReport));
    setReportList(newReportList);
    setSelectAllReport(!selectAllReport);
  };

  return (
    <div className="container-main-left">
      {/* page mapping section */}
      <p className="bookmark" style={{ width: "95%" }}>
        <span className="bookmark-parent-page">{currentTouch.name} &gt; </span>
        <span className="bookmark-page"> Consents </span>
      </p>

      {/* filter and print section */}
      <div className="consentmain-menu hidden-onprint">
        <ul className="consentmain-menu-left">
          <li>Actions</li>
          <li>
            <div className="dropdown-box">
              <p>{filter.action}</p>
              <button>
                <i
                  className="ci-chevron_down"
                  style={{ fontSize: "10px", color: "#575757" }}
                ></i>
              </button>
            </div>
          </li>
          <li>
            <button
              onClick={() => window.print()}
              className="consentmain-button button-print"
            >
              <i
                className="ci-download"
                style={{ fontSize: "24px", color: "black" }}
              ></i>
              <span>Print</span>
            </button>
          </li>
        </ul>
        <ul className="consentmain-menu-right wide-onprint">
          <li>Filter</li>
          <li>
            <Dropdown
              fieldName="status"
              dataObjectActivateState={selectFilter}
              setDataObjectActivateState={setSelectFilter}
              dataObjectState={filter}
              setDataObjectState={setFilter}
              listOfData={filterList.status}
              moreBoxClass="consent-main-dropdown-box"
              moreListClass="consent-main-dropdown-list-box"
            />
          </li>
          <li>
            <Dropdown
              fieldName="type"
              dataObjectActivateState={selectFilter}
              setDataObjectActivateState={setSelectFilter}
              dataObjectState={filter}
              setDataObjectState={setFilter}
              listOfData={filterList.type}
              moreBoxClass="consent-main-dropdown-box"
              moreListClass="consent-main-dropdown-list-box"
            />
          </li>
          <li style={{ position: "relative" }}>
            <div className="dropdown-box consent-main-dropdown-box-date">
              <span>
                {filter.date.startDate
                  ? getShortDate(filter.date.startDate)
                  : null}
                -
                {filter.date.endDate ? getShortDate(filter.date.endDate) : null}
              </span>
              <span>
                <button
                  onClick={() =>
                    setSelectFilter({
                      ...selectFilter,
                      date: !selectFilter.date,
                    })
                  }
                  className="hidden-onprint"
                >
                  <i
                    className="ci-calendar_calendar"
                    style={{
                      fontSize: "24px",
                      color: "#575757",
                      padding: "0 8px",
                    }}
                  ></i>
                </button>
              </span>
            </div>
            <div className={selectFilter.date ? "date-picker-box" : "hidden"}>
              <Datepicker
                dateFrom={filter}
                setDate={setFilter}
                dateProp="date"
                datepickerActivateStatus={selectFilter.date}
              />
            </div>
          </li>
        </ul>
      </div>

      {/* data table section */}
      <table className="consentmain-body" width="100%">
        <thead>
          <tr>
            {/* check box */}
            <th className="table-header hidden-onprint">
              <button
                className={`checkbox ${
                  selectAllReport ? "checkbox--check" : null
                }`}
                onClick={selectAll}
              ></button>
            </th>
            {/* table head */}
            {table.map((item, ind) => {
              return (
                <th key={ind} className="table-header">
                  {item.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {reportList.map((report, ind) => {
            // report.date from DD/MM/YYYY but new Date('MM/DD/YY')
            const reportDate = new Date(
              report.date.slice(3, 6) +
                report.date.slice(0, 3) +
                report.date.slice(6)
            );
            return (
              // record filter by date range, status and type
              reportDate >= filter.date.startDate &&
                reportDate <= filter.date.endDate &&
                ((filter.status === "All status" &&
                  filter.type === "All types") ||
                  (filter.status === "All status" &&
                    report.type === filter.type) ||
                  (filter.type === "All types" &&
                    report.status === filter.status) ||
                  (report.type === filter.type &&
                    report.status === filter.status)) ? (
                <tr key={ind}>
                  {/* check box */}
                  <td className="table-record hidden-onprint">
                    <button
                      className={`checkbox ${
                        report.isSelect ? "checkbox--check" : null
                      }`}
                      onClick={() => selectOne(ind)}
                    ></button>
                  </td>
                  {/* each record field */}
                  {table.map((item, ind) => {
                    let field = "";
                    item.name.forEach(
                      (name, index) =>
                        (field +=
                          index === 0 ? report[name] : " " + report[name])
                    );
                    return (
                      <td
                        key={ind}
                        className="table-record"
                        onClick={() => getSelectConsent(report._id)}
                      >
                        {field}
                      </td>
                    );
                  })}
                </tr>
              ) : null
            );
          })}
        </tbody>
      </table>

      {/* pagination */}
      <Pagination
        state={filter}
        setState={setFilter}
        pagePropName="page"
        maxPagi={5}
      />
    </div>
  );
}

export default ConsentMain;
