import React from "react";
import Main from "./DashboardMain";
import Management from "./DashboardManagement";

function Dashboard() {
  return (
    <div className="container-main">
      <Main />
      <Management />
    </div>
  );
}

export default Dashboard;
