import React from "react";
import { useState, useEffect } from "react";
import "./style.css";
// import axios from '../../config/axios';
import { Link, useHistory } from "react-router-dom";
import logo from "@assets/image/dg-notext-white-transparent.png";
import achive from "@assets/image/achive.png";

import { loginHeadPageList } from "@constants/pageList";

function Register() {
  const history = useHistory();
  const [value, setValue] = useState({
    email: "",
    website: "",
    url: "",
    agree: false,
  });
  const [validate, setValidate] = useState({
    website: false,
    url: false,
    email: false,
    agree: false,
  });

  const disableBtn = () => {
    for (let key in validate) {
      if (!validate[key]) return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+\.[a-zA-Z]*$/.test(
        value.email
      )
    ) {
      setValidate({ ...validate, email: true });
    } else {
      setValidate({ ...validate, email: false });
    }
    // eslint-disable-next-line
  }, [value.email]);

  useEffect(() => {
    if (/^https:\/\/*$/.test(value.url)) {
      setValidate({ ...validate, email: true });
    } else {
      setValidate({ ...validate, email: false });
    }
    // eslint-disable-next-line
  }, [value.url]);

  useEffect(() => {
    if (value.website.length >= 3) {
      setValidate({ ...validate, website: true });
    } else {
      setValidate({ ...validate, website: false });
    }
    // eslint-disable-next-line
  }, [value.website]);

  const getValue = (e, field) => {
    const data = { ...value, [field]: e.target.value };
    setValue(data);
  };
  const loginHandler = (e) => {
    e.preventDefault();
  };
  return (
    <div className="login-container">
      <div className="bg-login bg-login-under"></div>
      <div className="bg-login bg-login-top">
        <div className="logo-login-wrap">
          <img src={logo} alt="logo" className="logo-login" />
        </div>
        <img src={achive} alt="decorate key" className="key-picture-login" />
        <div className="login-text-wrap">
          <p>Welcome to Data Guardian Platform.</p>
          <p>Already have an account?</p>
          <button
            onClick={() => {
              history.push("/");
            }}
            className="register-to-login"
          >
            Login now
          </button>
        </div>
      </div>
      <div className="login-content">
        <ul className="login-link-box">
          {loginHeadPageList.map((page, ind) => {
            return (
              <li key={ind}>
                <Link to={page.url} className="link">
                  {page.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="login-text-wrap">
          <p>Welcome to Data Guardian Platform.</p>
        </div>
        <div className="login-text-wrap">
          <p>Signup now.</p>
          <p className="login-subtopic">To start your free trial.</p>
        </div>
        <div className="login-text-wrap" style={{ width: "80%" }}>
          <div className="input-field-wrap" style={{ width: "100%" }}>
            <div className="input-field-login">
              <div className="input-field-register-space"></div>
              <input
                type="text"
                onChange={(e) => getValue(e, "email")}
                placeholder="Email Address"
                value={value.email}
              />
            </div>
          </div>
          <div className="input-field-wrap" style={{ width: "100%" }}>
            <div className="input-field-login">
              <div className="input-field-register-space"></div>
              <input
                type="text"
                onChange={(e) => getValue(e, "website")}
                placeholder="Website name"
                value={value.website}
              />
            </div>
          </div>
          <div className="input-field-wrap" style={{ width: "100%" }}>
            <div className="input-field-login">
              <div className="input-field-register-space"></div>
              <input
                type="text"
                onChange={(e) => getValue(e, "url")}
                placeholder="Website URL (https://...)"
                value={value.url}
              />
            </div>
          </div>
        </div>
        <div
          onClick={() => setValue({ ...value, agree: !value.agree })}
          className="register-agree-box"
          style={{ width: "80%" }}
        >
          <i
            className="ci-circle_check_outline"
            style={{
              fontSize: "22px",
              color: value.agree ? "var(--primary-color)" : "#C4C4C4",
            }}
          ></i>
          <span>I AGREE TO THE PRIVACY POLICY AND TERMS AND CONDITIONS</span>
        </div>
        <div className="login-text-wrap">
          <div></div>
          <button
            className="login-input-action-btn"
            onClick={loginHandler}
            disabled={disableBtn()}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
