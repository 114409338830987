import { useState, useContext, useEffect } from "react";
import "./style.css";
import TouchContext from "@context/TouchContext";
import GraphBar from "@components/GraphBar";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { fullMonthNameList } from "@constants/date";
import {
  initialGraph,
  initialDataGraph,
  initialOptionGraph,
} from "@constants/graph";
import { getMonthList } from "@utils/date";
import BtnCue from "@components/BtnCue";

// default month: this month
const date = new Date();
const thisMonth = {
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  fullMonth: fullMonthNameList[date.getMonth()],
};

const initialConsent = {
  universal: { trackable: 0, untrackable: 0, total: 0 },
  cookie: { trackable: 0, untrackable: 0, total: 0 },
};

function DashboardMain() {
  const { touches } = useContext(TouchContext);
  const [consentList, setConsentList] = useState([]);
  const [consent, setConsent] = useState(initialConsent);
  const [dataGraph, setDataGraph] = useState(initialGraph);
  const [selectMonth, setSelectMonth] = useState(thisMonth);
  const [isSelect, setIsSelect] = useState({
    month: false,
    cueTransac: false,
    cueUniversal: false,
    cueCookie: false,
  });

  const optionGraph = { ...initialOptionGraph };
  const currentTouch = touches.filter((touch) => touch.isActive === true)[0];

  const monthList = currentTouch.createDate
    ? getMonthList(
        thisMonth.month,
        thisMonth.year,
        +currentTouch.createDate.slice(5, 7),
        +currentTouch.createDate.slice(0, 4)
      )
    : [];

  // Fetch monthly statistics from dropdown month select box
  const fetchConsentList = async () => {
    try {
      // [เปลี่ยน API path] axios.get(`consents/statistics/${currentTouch._id}?month=${selectMonth.month}&year=${selectMonth.year}`);
      const res = await axios.get(`api/graph/${currentTouch._id}/2021-02`);
      const { data } = res.data;
      const graphLabels = [];
      const graphDataUniversal = [];
      const graphDataCookie = [];
      data.forEach((day) => {
        ["universal", "cookie"].forEach((type) => {
          day[type].total = day[type].trackable + day[type].untrackable;
          day[type].percent =
            day[type].total !== 0
              ? (day[type].trackable / day[type].total) * 100 + "%"
              : 0;
        });
        graphLabels.push(+day.date.slice(8, 10));
        graphDataUniversal.push(+day.universal.total);
        graphDataCookie.push(+day.cookie.total);
      });
      const newDataGraph = { ...initialDataGraph };
      newDataGraph.labels = graphLabels;
      newDataGraph.datasets[0].data = graphDataUniversal;
      newDataGraph.datasets[1].data = graphDataCookie;
      setConsentList(data);
      setConsent(data[0]);
      setDataGraph(newDataGraph);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchConsentList();
    // eslint-disable-next-line
  }, [selectMonth]);

  // Line graph options' function
  optionGraph.onClick = (e, element) => {
    if (element.length > 0) {
      const ind = element[0]._index;
      setConsent(consentList[ind]);
    }
  };
  optionGraph.tooltips.callbacks = {
    title: function (tooltipItems, data) {
      return data.datasets[tooltipItems[0].datasetIndex].label;
    },
    label: function (tooltipItems) {
      return tooltipItems.yLabel + " users";
    },
    footer: function (tooltipItems) {
      const ind = tooltipItems[0].index;
      return new Date(consentList[ind].date).toLocaleDateString();
    },
  };

  return (
    <div className="container-main-left">
      <p className="bookmark">
        <span className="bookmark-parent-page">
          {touches.filter((touch) => touch.isActive)[0].name} &gt;{" "}
        </span>
        <span className="bookmark-page"> Dashboard </span>
      </p>

      {/* Notification to upgrade */}
      <div className="dashboardmain-noti-box">
        <button className="button-green">UPGRADE</button>
        <p>To unlock premium support and get more feature now?</p>
      </div>

      {/* graph line */}
      <div className="dashboardmain-graph-line-box">
        <div className="dashboardmain-graph-line-head">
          <p className="dashboardmain-graph-line-topic">
            <span>Total consent transaction</span>
            <BtnCue
              fieldName="cueTransac"
              isSelectState={isSelect}
              setIsSelectState={setIsSelect}
              text={["text1", "text2"]}
              btnColor="#D0D1D2"
            />
          </p>
          <ul className="dashboardmain-graph-line-menu">
            <li>
              <div
                className="dashboardmain-line"
                style={{ backgroundColor: "#ece9f1", width: "61px" }}
              ></div>
            </li>
            <li style={{ color: "#D0D1D2" }}>Provisions Month</li>
            <li className="dropdown-box-wrap">
              <div className="dropdown-box" style={{ width: "150px" }}>
                <span>{selectMonth.fullMonth + " " + selectMonth.year}</span>
                <button
                  onClick={() =>
                    setIsSelect({ ...isSelect, month: !isSelect.month })
                  }
                  className="hidden-onprint"
                  style={{ width: "30px" }}
                >
                  <i
                    className="ci-calendar_event"
                    style={{
                      color: "rgba(66, 47, 138, 0.87)",
                      fontSize: "24px",
                    }}
                  ></i>
                </button>
              </div>
              <ul
                className={`hidden ${
                  isSelect.month ? "dropdown-list-box" : null
                }`}
                style={{ width: "100%", height: "150px", overflow: "auto" }}
              >
                {monthList.map((item, ind) => {
                  return (
                    <li
                      key={ind}
                      onClick={() => {
                        setIsSelect({ ...isSelect, month: false });
                        setSelectMonth(item);
                      }}
                    >
                      {item.fullMonth + " " + item.year}
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <button
                className="dropdown-box"
                style={{
                  width: "40px",
                  minWidth: "40px",
                  justifyContent: "center",
                }}
              >
                <i
                  className="ci-more_horizontal"
                  style={{ color: "rgba(66, 47, 138, 0.87)", fontSize: "24px" }}
                ></i>
              </button>
            </li>
          </ul>
        </div>
        {/* graph body */}
        <div className="graph-line-box">
          <Line data={dataGraph} options={optionGraph} height={300} />
        </div>
      </div>

      {/* graph bar */}
      <div className="dashboardmain-graph-bars-box">
        {/* graph bar for universal consent */}
        <GraphBar
          consent={consent || initialConsent}
          consentType="universal"
          colorPrimary="var(--primary-color)"
          colorSecondary="var(--primarylight-color)"
          textList={["consent", "Universal"]}
        />
        {/* graph bar for cookie consent */}
        <GraphBar
          consent={consent || initialConsent}
          consentType="cookie"
          colorPrimary="var(--secondary-color)"
          colorSecondary="var(--secondarylight-color)"
          textList={["consent", "Cookie"]}
        />
      </div>
    </div>
  );
}

export default DashboardMain;
