export default function NavButton({ children, onClick, iconName }) {
  return (
    <button type="button" onClick={onClick}>
      <i
        className={iconName}
        style={{ color: "#575757", fontSize: "24px" }}
      ></i>
      {children}
    </button>
  );
}
