import "./style.css";

function CreateConsentScript({ newConsent }) {
  console.log(newConsent);

  const script = "Generated script will show here";

  return (
    <div className="body-consent-script">
      <div className="consent-link-box">{script}</div>
      <button
        onClick={() => navigator.clipboard.writeText(script)}
        className="copy-btn"
      >
        Copy!
      </button>
    </div>
  );
}

export default CreateConsentScript;
