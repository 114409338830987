import { useState } from "react";
import "./style.css";
import Transaction from "@components/Transaction";
import { fontList } from "@constants/dataDropdownList.js";
import Dropdown from "@components/DropdownList";

const layoutStyle = ["A", "B", "C"];

function CreateConsentSetting({ newConsent, setNewConsent }) {
  const [selectFont, setSelectFont] = useState({ font: false });
  const getValue = (e, field) => {
    const data = { ...newConsent, [field]: e.target.value };
    setNewConsent(data);
  };
  return (
    <div className="container-createconsent-child">
      <h3 className="createconsent-topic">Consent appearance settings.</h3>
      <div className="subcontainer-createconsentsetting">
        <div>
          <Transaction
            height={630}
            width={420}
            transactionStyle={newConsent.layout}
            btnColor={newConsent.btnColor}
            bgColor={newConsent.bgColor}
          />
        </div>
        <div className="content-createconsentsetting">
          <div className="input-field-wrap">
            <label htmlFor="statement">Short privacy statement</label>
            <input
              className="input-field"
              type="text"
              placeholder="Privacy short statement"
              id="statement"
              onChange={(e) => getValue(e, "statement")}
              value={newConsent.statement}
            />
          </div>
          <div className="input-field-wrap">
            <label htmlFor="bgColor">Background Color</label>
            <div className="input-field">
              <input
                type="text"
                placeholder=""
                onChange={(e) => getValue(e, "bgColor")}
                value={newConsent.bgColor}
              />
              <input
                className="input-field-color"
                type="color"
                id="bgColor"
                onChange={(e) => getValue(e, "bgColor")}
                value={newConsent.bgColor}
              />
            </div>
          </div>
          <div className="input-field-wrap">
            <label htmlFor="btnColor">Submit button color</label>
            <div className="input-field">
              <input
                type="text"
                placeholder=""
                onChange={(e) => getValue(e, "btnColor")}
                value={newConsent.btnColor}
              />
              <input
                className="input-field-color"
                type="color"
                id="btnColor"
                onChange={(e) => getValue(e, "btnColor")}
                value={newConsent.btnColor}
              />
            </div>
          </div>
          <div className="input-field-wrap">
            <label>Font Family</label>
            <Dropdown
              fieldName="font"
              dataObjectActivateState={selectFont}
              setDataObjectActivateState={setSelectFont}
              dataObjectState={newConsent}
              setDataObjectState={setNewConsent}
              listOfData={fontList}
              moreBoxClass="createconsent-setting-dropdown-box"
              moreListClass="createconsent-setting-dropdown-list-box"
            />
          </div>
          <div className="createConsent-layout-container">
            {layoutStyle.map((item, ind) => (
              <div
                key={ind}
                onClick={() => setNewConsent({ ...newConsent, layout: item })}
                className="createConsent-layout-wrap"
              >
                <span htmlFor={item}>{item} </span>
                <Transaction
                  height={260}
                  width={140}
                  transactionStyle={item}
                  btnColor={newConsent.btnColor}
                  bgColor={newConsent.bgColor}
                  id={item}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateConsentSetting;
