const purposeList = [
  {
    shortCode: "",
    description: "",
    action: "Create New Purpose",
  },
  {
    description:
      "To enable the entity to carry out the core functions of its site/app/services.",
    shortCode: "Core Function",
  },
  {
    description:
      "To provide contracted or requested services to the PII Subject.",
    shortCode: "Contracted Service",
  },
  {
    description:
      "To deliver contracted or requested services to the PII Subject.",
    shortCode: "Delivery",
  },
  {
    description:
      "Communicating with you about information or services you specifically request.",
    shortCode: "Contact Requested",
  },
  {
    description:
      "Providing you with a personalised experience of our site/app/service.",
    shortCode: "Personalized Experience",
  },
  {
    description:
      "Communicating with you about our other services you may be interested in.",
    shortCode: "Marketing",
  },
  {
    description:
      "Communicating with you about the services of third parties you may be interested in.",
    shortCode: "Marketing Third Parties",
  },
  {
    description:
      "Providing the information to third parties to deliver our services on our behalf.",
    shortCode: "SharingUsefor Delivery",
  },
  {
    description:
      "Providing the information to third parties to enable them to communicate with you about their own services you may be interested in.",
    shortCode: "Disclosure for Marketing",
  },
  {
    description:
      "Providing the information to third parties to enable them to deliver or improve their own services to you.",
    shortCode: "3rd Party Disclosure for Core Function",
  },
  {
    description: "Complying with our legal obligations for record keeping.",
    shortCode: "Legally Required Data Retention",
  },
  {
    description:
      "Complying with our legal obligations to provide the information to law enforcement or other regulatory/government bodies.",
    shortCode: "Required by Law Enforcement or Government",
  },
  {
    description: "Protecting your vital and health interests.",
    shortCode: "Protecting Your Health",
  },
  {
    description:
      "Protecting our legitimate interests, yours or those of a third party.",
    shortCode: "Protecting Our Interests",
  },
  {
    description:
      "Measure or improve our performance or the delivery of our services.",
    shortCode: "Improve Performance",
  },
];

module.exports = { purposeList };
