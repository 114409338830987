import { fullMonthNameList } from "@constants/date";

export function getMonthList(lastMonth, lastYear, firstMonth, firstYear) {
  const monthList = [];
  for (let year = lastYear; year >= firstYear; year--) {
    if (year !== lastYear) {
      for (let month = 12; month >= firstMonth; month--)
        monthList.push({
          year,
          month,
          fullMonth: fullMonthNameList[month - 1],
        });
    } else {
      for (let month = lastMonth; month >= 1; month--)
        monthList.push({
          year,
          month,
          fullMonth: fullMonthNameList[month - 1],
        });
    }
  }
  return monthList;
}

export function getYearList(startYear, endYear = new Date().getFullYear()) {
  const yearList = [];
  for (let year = endYear; year >= startYear; year--) yearList.push(year);
  return yearList;
}

export function getShortDate(date) {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
}
